body {
    font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    color: #34495e;
    margin: 0;
}

#header {
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0,0,0,0.25);
    padding: 0px 60px;
    position: relative;
    z-index: 2;
}

.navbar-toggle {
    background: #efefef;
}

.navbar-toggle span {
    background: #000;
}

.navbar-nav a, #logo {
    color: #2c3e50;
}

#logo {
    font-size: 1.5em;
    font-weight: 500;
}

.input-group .form-control {
    z-index: 1;
}